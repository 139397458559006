import { useStaticQuery, graphql } from 'gatsby';

const usePartnerImages = () =>
  useStaticQuery(graphql`
    query {
      innonic: file(relativePath: { eq: "partners/innonic.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tokeportal: file(relativePath: { eq: "partners/tokeportal.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bosch: file(relativePath: { eq: "partners/bosch.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      oxo: file(relativePath: { eq: "partners/oxo.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      microsoft: file(relativePath: { eq: "partners/microsoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pInvent: file(relativePath: { eq: "partners/p-invent.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lunar: file(relativePath: { eq: "partners/lunar.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vespucci: file(relativePath: { eq: "partners/vespucci.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      tunsgram: file(relativePath: { eq: "partners/tunsgram.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mkb: file(relativePath: { eq: "partners/mkb.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      digitalFactory: file(relativePath: { eq: "partners/digital.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startitup: file(relativePath: { eq: "partners/startitup.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      efott: file(relativePath: { eq: "partners/efott.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      szintezis: file(relativePath: { eq: "partners/szintezis.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      datatronic: file(relativePath: { eq: "partners/datatronic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bmeZ10: file(relativePath: { eq: "partners/bmez10.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      drukka: file(relativePath: { eq: "partners/drukka.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mol: file(relativePath: { eq: "partners/mol.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smartFutureLab: file(relativePath: { eq: "partners/mvm.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      antenna: file(relativePath: { eq: "partners/antenna.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startupCampus: file(relativePath: { eq: "partners/campus.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      nak: file(relativePath: { eq: "partners/nak.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bnlStart: file(relativePath: { eq: "partners/bnl.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startItKh: file(relativePath: { eq: "partners/kh.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      checkInnTurisztikai: file(relativePath: { eq: "partners/checkin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      laserConsult: file(relativePath: { eq: "partners/laser.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      creativeAccelerator: file(relativePath: { eq: "partners/creative.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startupHungary: file(relativePath: { eq: "partners/startup_hungary.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      designTerminal: file(relativePath: { eq: "partners/design_terminal.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      izinta: file(relativePath: { eq: "partners/izinta.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      ivsz: file(relativePath: { eq: "partners/ivsz.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      greenBrother: file(relativePath: { eq: "partners/green_brother.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      meOutGroup: file(relativePath: { eq: "partners/meout_group.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      brancsKozosseg: file(relativePath: { eq: "partners/brancskozosseg.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
    }
  `);

export default usePartnerImages;
