import usePartnerImages from './usePartnerImages';

export default function usePartners() {
  const partnerImages = usePartnerImages();
  const partners = {
    innonic: {
      title: 'Innonic',
      logo: partnerImages.innonic,
      offers: [
        {
          title: 'Ingyenes Innonic Academy online képzés',
          lead:
            'A projektcsapatban részt vevő hallgatók ingyenesen elvégezhetik az Innonic Academy online képzéseit.'
        },
        {
          title: 'Shoprenter webshop használat',
          lead:
            'Kedvezményes bérlést biztosít a Shoprenter webshop motorjának használatához: 10 csapat féláron kapja az Éves Silver és az Éves Gold webshopot, amely mellé ingyenes online oktatás és konzultáció jár.'
        },
        {
          title: 'Szakterületi mentoráció',
          lead:
            'Adott szakterületre vonatkozó mentorációt biztosít HSUP hallgatók számára.'
        }
      ]
    },
    tokeportal: {
      title: 'Tőkeportál',
      logo: partnerImages.tokeportal,
      offers: [
        {
          title: 'Befektetésre való felkészítés',
          lead:
            '1 projektcsapat számára heti 1 alkalommal, fél órában befektetésre való felkészítést biztosít.'
        },
        {
          title: 'Tanácsadás tőkebevonási stratégia kialakításához',
          lead:
            '1 projektcsapat számára segítséget nyújt a tőkebevonási stratégia kialakításában.'
        },
        {
          title: 'Közösségi finanszírozási kampány indítása',
          lead:
            '1 projektcsapat számára biztosítja a közösségi finanszírozási kampány elindítását.'
        },
        {
          title: 'Workshop IP témában',
          lead:
            'Workshopot tart (online vagy offline formában) szellemi tulajdon témakörben.'
        },
        {
          title: 'Workshop közösségi finanszírozás témában',
          lead:
            'Workshopot tart (online vagy offline formában) közösségi finanszírozás, crowdfunding témakörben.'
        }
      ]
    },
    bosch: {
      title: 'Bosch',
      logo: partnerImages.bosch,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead:
            'A HSUP Programban kidolgozásra kerülő - Bosch által javasolt vagy támogatásra érdemesnek ítélt - témák számához igazodóan mentorokat biztosít a hallgatók számára.'
        },
        {
          title: 'Ipari szakértői konzultáció',
          lead:
            'Ipari szakértői konzultációs lehetőséget ajánl fel a Bosch által javasolt vagy általa támogatásra érdemesnek ítélt témá(k)hoz kapcsolódóan a témán dolgozó hallgató(k) számára.'
        },
        {
          title: 'Bosch-díj ',
          lead:
            'A Bosch által leginnovatívabbnak és iparilag leginkább hasznosíthatónak ítélt téma kidolgozója részére díjat ajánl fel és ad át a Program záróeseményének alkalmával (Bosch-díj).'
        }
      ]
    },
    oxo: {
      title: 'OXO Labs',
      logo: partnerImages.oxo,
      offers: [
        {
          title: 'Szakmai konzultáció',
          lead:
            '3 HSUP csapat számára 2 órás konzultációs lehetőséget biztosít.'
        },
        {
          title: 'Szakmai konzultáció',
          lead: 'Szakmai mentort delegál a HSUP programba.'
        }
      ]
    },
    microsoft: {
      title: 'Microsoft',
      logo: partnerImages.microsoft,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead:
            'A Microsoft által kiválasztott 3 B2B fókuszú ötleten dolgozó csapat 2-2 óra mentorációban részesül.'
        },
        {
          title: '100 dollár értékű Azure felhő szolgáltatás igénybevétele',
          lead:
            'Minden MVP-t fejlesztő csapat számára felajánlják, hogy vegye igénybe a diákoknak szánt 100 dollár értékű Azure felhő szolgáltatást. Ennek linkje: https://azure.microsoft.com/en-us/free/students/'
        },
        {
          title: 'Jelentkezési lehetőség a Microsoft for startups programjába',
          lead:
            'A 20 legjobb csapat meghívást kap a Microsoft for startups programjába, ahová az alábbi linken maga jelentkezhet: https://startups.microsoft.com/ A felvétel a programba nem garantált.'
        }
      ]
    },
    pInvent: {
      title: 'Knowledge To Money Club',
      logo: partnerImages.pInvent,
      offers: [
        {
          title: 'Üzleti angyal hálózatnak való bemutatás',
          lead:
            'Az MVP-vel rendelkező HSUP startup-okat bemutatja a CE-Connector programban létrehozott üzleti angyal hálózatának.'
        }
      ]
    },
    lunar: {
      title: 'Lunar Program',
      logo: partnerImages.lunar,
      offers: [
        {
          title: '3 hónapos Design Thinking Workshop',
          lead:
            '5 csapat részére biztosítja az inkubátor program első fázisát, a 3 hónapos Design Thinking Workshopot, amely keretén belül dedikált facilitátor segítségével a csapatok az ötleteiket kutathatják, prototipizálhatják, tesztelhetik. A kiválasztás a Lunar Program saját módszere szerint történik.'
        },
        {
          title:
            'Lunar Program inkubátorába, a Design Station-be való meghívás',
          lead:
            'Felajánlja, hogy a HSUP Demo Day-be bekerült csapatok jelentkezhetnek, meghívást kaphatnak a Lunar Program inkubátorába, a Design Station-be.'
        },
        {
          title: 'Lunar Program eseményeken való részvétel',
          lead:
            'A HSUP programban résztvevő csapatok számára biztosítja, hogy részt vehetnek az alábbi Lunar Program eseményeken: Inspiration Hour: latókörbővítő beszélgetés sikeres startupperekkel; Startup Matchmaking: csapattagok keresése, networking; Info Night: a Lunar Program infóestje.'
        }
      ]
    },
    vespucci: {
      title: 'Vespucci Partners',
      logo: partnerImages.vespucci,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead:
            'Az eseményen résztvevő, legfeljebb 3 kiválasztott csapat számára mentorációt biztosít.'
        },
        {
          title: 'Gyakornoki vagy főállású elhelyezkedési lehetőség',
          lead:
            'A HSUP hallgatóknak lehetőséget közvetít saját portfólió cégei között gyakornoki vagy főállású elhelyezkedésre.'
        },
        {
          title: 'KKV igényekre ötletkidolgozás lehetősége',
          lead:
            '„Challenge Mapping” koordinálása KKV csoportokkal, ezzel elősegítve a HSUP hallgatók számára, hogy a különböző felmerülő igényekre dolgozzanak ki megoldásokat.'
        },
        {
          title: 'Bemutatkozási lehetőség szilícium-völgyi befektetők előtt',
          lead:
            '1 kiválasztott csapat számára lehetőséget biztosít, hogy bemutatkozzon szilícium-völgyi angyalbefektetők előtt.'
        }
      ]
    },
    tunsgram: {
      title: 'Tungsram',
      logo: partnerImages.tunsgram,
      offers: [
        {
          title: 'Tungsram gyárlátogatás',
          lead:
            'Budapesti telephelyén szakmai vezetéssel történő gyárlátogatást biztosít (max. 5 különböző időpontban, alkalmanként max. 15 fő részvételével).'
        },
        {
          title: 'Szakmai gyakorlati és szakdolgozatírási lehetőség',
          lead:
            'Lehetőséget nyújt max. 3 fő számára informatikai és gazdasági témákban szakmai gyakorlat teljesítésére, illetve szakdolgozatírásra.'
        },
        {
          title:
            'Személyes szaktanácsadás + vállalat felsővezetésével való találkozó',
          lead:
            '1 csapat számára, amely a Tungsram által meghirdetett vállalati kihívásra dolgoz ki ötletet, biztosítja a személyes szaktanácsadást, valamint a vállalat felsővezetésével történő találkozást.'
        },
        {
          title: 'Tungsram innovációs versenyére jelentkezési lehetőség',
          lead:
            'A HSUP program elvégzését követően alakuló startup cégeknek lehetőséget biztosít a Tungsram által szervezett innovációs versenyre történő jelentkezésre.'
        }
      ]
    },
    mkb: {
      title: 'MKB Fintechlab',
      logo: partnerImages.mkb,
      offers: [
        {
          title: 'Stripe startup csomag + banki sandbox hozzzáférés',
          lead:
            'Felajánlják a Stripe által biztosított startup csomagot és hozzáférést adnak a banki sandboxhoz tesztelés céljából.'
        },
        {
          title: 'Exklúzív szakmai támogatás',
          lead: 'Exkluzív szakmai támogatás 3 HSUP hallgatói csapat számára.'
        },
        {
          title: '4 szakmai mentor biztosítása',
          lead:
            '2 Szakmai mentort (UX, PSD2), egy üzleti mentort, illetve egy startupper mentort a korábbi befektetéseik közül. Ők mindannyian kéthetente egy mentorálást tudnak vállalni előre egyeztetett időpontban.'
        }
      ]
    },
    digitalFactory: {
      title: 'Digital factory',
      logo: partnerImages.digitalFactory,
      offers: [
        {
          title: 'Prototipizálási tanácsadás',
          lead:
            'Egy HSUP projekt csapat számára, előre egyeztetett időpontban konzultációt és tanácsadást biztosít a vállalkozási ötlet prototipizálásához.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a programba.'
        }
      ]
    },
    startitup: {
      title: 'Startitup Győr',
      logo: partnerImages.startitup,
      offers: [
        {
          title: 'Egy napos program',
          lead:
            'Egy napos program a második félév folyamán előre egyeztett időpontban. '
        },
        {
          title: 'Start it @K&H inkubátorház látogatás',
          lead: 'Start it @K&H inkubátorház meglátogatása.'
        },
        {
          title: 'Ebéd startupperekkel',
          lead: 'Több helyi startuppal egy beszélgetős ebéd'
        },
        {
          title: 'Kézműves sörözés válallkozókkal',
          lead: 'Régiós tapasztalt vállalkozókkal kézműves sörözés.'
        },
        {
          title: 'Beszélgetés IT szakemberekkel',
          lead: 'Beszélgess a régió vezető IT szakembereivel.'
        },
        {
          title: '3D prototípus gyártás',
          lead: 'Hogyan működik egy 3D-s prototípus gyártás?'
        },
        {
          title: 'Szakmai tanácsadás',
          lead: 'Egy órás tanácsadás a Start IT up Győr szakmai csapatával'
        },
        {
          title: 'UX tanácsadás',
          lead: 'Egy órás UX tanácsadás'
        },
        {
          title: 'Design thinking alapok',
          lead: 'Ismerd meg a Design Thinking alapjait '
        },
        {
          title: 'Cégalapítási alapismeretek',
          lead: 'Cégalapítási alapismeretek'
        },
        {
          title: 'Ideathon4 ötletverseny részvétel',
          lead:
            'Részvétel az ország egyik legnagyobb ötletversenyén: www.ideathon.me'
        }
      ]
    },
    efott: {
      title: 'EFOTT',
      logo: partnerImages.efott,
      offers: [
        {
          title: 'Megjelenési lehetőség',
          lead:
            'Fesztiválhoz köthető az innovatív ötleted? Az EFOTT lehetőséget biztosít a következő fesztiválon hogy megmutathassátok amin a félévben dolgoztatok!  '
        }
      ]
    },
    szintézis: {
      title: 'Szintézis-Net Kft.',
      logo: partnerImages.szintezis,
      offers: [
        {
          title: 'Üzletfejlesztési és/vagy termékfejlesztési tanácsadás',
          lead:
            '2 órás, ingyenes üzletfejlesztési és/vagy termékfejlesztési tanácsadást biztosít 3 HSUP projekt csapat számára.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a HSUP projekt csapatoknak.'
        }
      ]
    },
    datatronic: {
      title: 'Datatronic Kft.',
      logo: partnerImages.datatronic,
      offers: [
        {
          title: 'Ingyenes szolgáltatások',
          lead:
            'Ingyenes Cloud Native, Kubernetes, CaaS, IaaS, SaaS szolgáltatások a HSUP hallgatónak '
        },
        {
          title: 'Ingyenes open office és infrastruktúra',
          lead:
            'Ingyenes open office és infrastruktúra használat a Datatronic irodájában '
        }
      ]
    },
    bmeZ10: {
      title: 'BME Z10 Inkubátor',
      logo: partnerImages.bmeZ10,
      offers: [
        {
          title: 'Inkubátor és Fablab használat',
          lead: 'BME Z10 inkubátor és a fablab kedvezményes használata. '
        },
        {
          title:
            'Z10 Akcelerátorba való jelentkezés és azon ingyenes részvétel',
          lead:
            'A Z10 Akcelerátorba való jelentkezés és azon ingyenes részvétel. Ennek előnye a HSUP résztvevők számára az, hogy az akcelerátor végén, 2022 nyár elején akár extra tőkebefektetéssel tudnak tovább haladni a HSUP ösztöndíj felhasználását követően.'
        }
      ]
    },
    drukka: {
      title: 'Drukka Kft. és Intellitext Kft.',
      logo: partnerImages.drukka,
      offers: [
        {
          title: 'Közös ebéd szakemberekkel',
          lead:
            'Egy közös ebéd a UX/UI designerrel, front vagy backend fejlesztővel, ahol felteheted a szakmai kérdésedidet! '
        },
        {
          title: 'Hub 55 coworking bérlet',
          lead:
            'Egy havi Hub55 coworking bérlet: free wifi, gamer monitor - all you can drink, kávé, snack kiegészítéssel. '
        },
        {
          title: 'Founder Institute akceleráció',
          lead: 'Founder Institute akceleráció részvétel a 2. cohortban. '
        }
      ]
    },
    mol: {
      title: 'MOL',
      logo: partnerImages.mol,
      offers: [
        {
          title: 'Mentoráció',
          lead:
            'Szakmai mentorálással 3 óra ingyenes ipari validációt, illetve go-to-market stratégiai tanácsadást biztosít maximum 4 HSUP projektcsapat számára, a vállalat Tech Transfer szervezetével.'
        },
        {
          title: 'Mentorok biztosítása',
          lead: 'Mentorokat biztosít a program számára.'
        }
      ]
    },
    smartFutureLab: {
      title: 'Smart Future Lab inkubátor, MVM Csoport',
      logo: partnerImages.smartFutureLab,
      offers: [
        {
          title: 'Mentorok biztosítása',
          lead:
            'Az SFL és az MVM csoport üzleti és szakterületi mentorait megtalálod majd a HSUP platformon márciustól.'
        },
        {
          title: 'Inkubációs program',
          lead:
            'Az SFL inkubációs programja nyitva áll a HSUP hallgatói csapatainak. Jelentkezéseteket folyamatosan várják az info@smartfuturelab.hu e-mail címen, jelentkezés után az SFL kollégái felveszik Veled a kapcsolatot. https://smartfuturelab.hu/'
        }
      ]
    },
    antenna: {
      title: 'Antenna Hungária',
      logo: partnerImages.antenna,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead: 'Szakmai mentorokat biztosít a programba.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            'Üzleti tervezés témában 2 csapat számára 1-1 óra konzultációs lehetőséget biztosít.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            'Kampánytervezés témában 2 csapat számára 2-2 óra konzultációs lehetőséget biztosít.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            'Közműves és ipari IoT felhasználások témában 1 csapat számára 2x1 óra konzultációs lehetőséget biztosít.'
        },
        {
          title: 'Szakmai konzultáció',
          lead:
            'Szolgáltatás vagy termék fejlesztés menedzsmenthez, marketing  témában, továbbá rendszerfejlesztés mobil, web és egyéb platformokon való megjelenés témában 2 csapat számára 2x2 óra konzultációs lehetőséget biztosít.'
        }
      ]
    },
    startupCampus: {
      title: 'Startup Campus',
      logo: partnerImages.startupCampus,
      offers: [
        {
          title: 'Szakmai mentoráció + üzletfejlesztési tanácsadás',
          lead:
            'Szakmai mentoráció korai fázisú startup vállalkozások számára. Vállalkozásfejlesztési, üzletfejlesztési tanácsadás a Startup Campus szakembereitől.'
        },
        {
          title:
            'Egyenes ági bekerülés a Startup Campus Inkubációs programjába',
          lead:
            'A program legjobb csapatai számára egyenes ági bekerülés a Startup Campus Inkubációs programjába, ami ősszel indul.'
        }
      ]
    },
    nak: {
      title: 'NAK TechLab',
      logo: partnerImages.nak,
      offers: [
        {
          title: 'Agrárszakmai mentoráció',
          lead:
            'Agrár releváns projektek számára agrárszakmai mentorálást (állattenyésztés és szántóföldi növénytermesztés területén) biztosít havonta egy alkalommal a 2022-es program ideje alatt.'
        },
        {
          title: 'Egyenes ági bekerülés a NAK TechLab hackathonjára',
          lead:
            'Egyenes ági bekerülést biztosít 2 projekt csapat számára a NAK TechLab 2022. májusi hackathonjára. Jelentkezés start: március 1.'
        }
      ]
    },
    bnlStart: {
      title: 'BNL Start Partners Kft.',
      logo: partnerImages.bnlStart,
      offers: [
        {
          title: 'Részvételi lehetőség a BNL Start rendezvényeken',
          lead:
            '2-3 hallgatói csapat számára ingyenes részvétell lehetőséget biztosít a BNL Start által szervezett vagy támogatott rendezvényeken.'
        },
        {
          title: 'Szakmai tanácsadás',
          lead:
            'Előzetes egyeztetés alapján tanévenként 2x30 perces szakmai tanácsadást biztosít max. 3 hallgatói csapat számára.'
        }
      ]
    },
    startItKh: {
      title: 'Start it @K&H',
      logo: partnerImages.startItKh,
      offers: [
        {
          title: '"Zöld kártya" az inkubátor őszi kiválasztásában',
          lead:
            'A legkiemelkedőbb, általuk kiválasztott 2 csapat „zöld kártyát” kap az inkubátor őszi kiválasztásában, és egyből az utolsó fordulóba kerülnek.'
        },
        {
          title: 'Közösségi iroda használat',
          lead:
            'A hallgatók belátást kaphatnak az inkubátorba: előzetes egyeztetés után használatba vehetik a közösségi irodát egy-egy napra.'
        }
      ]
    },
    checkInnTurisztikai: {
      title: 'CheckINN Kft.',
      logo: partnerImages.checkInnTurisztikai,
      offers: [
        {
          title: 'Turisztikai szakmai mentoráció',
          lead:
            '5 hallgatói csapat számára 2x2 óra turisztikai szakmai mentorálást biztosít.'
        },
        {
          title: 'Ingyenes éves előfizetés szakmai lapokra',
          lead:
            'A Turizmus Kft. által kiadott szakmai lapokra történő éves előfizetést biztosít a mentorált csapatok számára. (1 db újság választható / csapattag: Turizmus.com magazin / Business Traveller / Vendéglátás magazin).'
        }
      ]
    },
    laserConsult: {
      title: 'Laser Consult Kft.',
      logo: partnerImages.laserConsult,
      offers: [
        {
          title: 'Találkozási lehetőség angyalbefektetőkkel',
          lead:
            'Kiválasztott hallgatói csapatok számára lehetőséget biztosít angyalbefektetőkkel való kapcsolatfelvételre.'
        },
        {
          title: 'Workshop',
          lead: 'Workshopot tart hallgatói csapatok számára.'
        }
      ]
    },
    creativeAccelerator: {
      title: 'Creative Accelerator',
      logo: partnerImages.creativeAccelerator,
      offers: [
        {
          title: 'Befektetés szerzési lehetőség',
          lead:
            'Kiválasztott hallgatói csapatok számára befektetés szerzési lehetőséget biztosít.'
        },
        {
          title: 'Workshop',
          lead:
            'Workshopot tart hallgatói csapatok számára befektetéshez kapcsolódó témakörben.'
        }
      ]
    },
    startupHungary: {
      title: 'Startup Hungary Alapítvány',
      logo: partnerImages.startupHungary,
      offers: [
        {
          title: 'Workshop',
          lead:
            'Évente 1-2 alkalommal dedikált workshopot szervez 20 HSUP csapat számára, melynek keretében hasznos gyakorlati tudást szerezhetnek az iparág legelismertebb képviselőitől.'
        },
        {
          title: 'Szakmai mentoráció',
          lead: '1 szakmai mentor biztosít a HSUP-ba.'
        },
        {
          title: 'Konzultációs lehetőség',
          lead:
            '2 órás konzultációs lehetőséget biztosít 3 HSUP csapat számára.'
        },
        {
          title: 'Bemutatkozási lehetőség First Monday eseményen',
          lead:
            '1 HSUP csapat számára bemutatkozási lehetőséget biztosít egy First Monday eseményen.'
        }
      ]
    },
    designTerminal: {
      title: 'Design Terminal Nonprofit Kft.',
      logo: partnerImages.designTerminal,
      offers: [
        {
          title: 'Orientációs tanácsadás',
          lead:
            'A 2022-es program ideje alatt összesen 6 alkalommal orientációs tanácsadást biztosít (piacra lépési stratégia kialakítása, finanszírozás szerzés, branding) a HSUP csapatai számára.'
        }
      ]
    },
    izinta: {
      title: 'Izinta Kft.',
      logo: partnerImages.izinta,
      offers: [
        {
          title: 'Egészségipari szakmai konzultáció',
          lead: '30 éves egészségipari tapasztalatával 2x2 órás lehetőséget biztosít a hallgatóknak, hogy a cég szakterületihez kapcsolódó kérdéseket fogalmazzanak meg és a válaszokból nyert szaktudást leendő projektjeiben hasznosítsák.'
        },
        {
          title: 'Üzleti ebéd',
          lead: 'Üzleti ebédet szervez Budapesten az Izinta Kft. vezetőivel, akik egészségipari - üzleti tanácsokkal látják el a hallgatókat. A hallgatók feltehetik kérdéseiket, az ebéd során projektjeik szóban véleményezésére kerülnek a vezetők tapasztalati alapján.'
        }
      ]
    },
    ivsz: {
      title: 'IVSZ',
      logo: partnerImages.ivsz,
      offers: [
        {
          title: 'Szakmai mentoráció',
          lead: 'Szakmai mentort biztosít a vállalati  kihívásukon dolgozó HSUP csapat számára.'
        },
        {
          title: 'Rendezvényen való részvétel',
          lead: 'Lehetőséget biztosít HSUP hallgatók részére, hogy részt vegyenek az IVSZ által szervezett startup alapítással/vállalkozással kapcsolatos eseményeken.'
        }
      ]
    },
    greenBrother: {
      title: 'Green Brother',
      logo: partnerImages.greenBrother,
      offers: [
        {
          title: 'Vállalkozásfejlesztési oktatás és mentorálás',
          lead: '1-2 csapat számára vállalkozásfejlesztési oktatást és mentorálás biztosít.'
        },
        {
          title: 'Támogatás nyújtása vállalati stratégiai partnerség kialakításában és nemzetközi üzletfejlesztésben',
          lead: 'Azon csapat(ok) számára, akik részt vettek a Green Brother által tartott mentorációban, támogatást nyújt vállalati stratégiai partnerség kialakításában és nemzetközi üzletfejlesztésben, amennyiben van a portfóliójában és hálózatában a csapattal üzletileg összekapcsolható szereplő.'
        }
      ]
    },
    meOutGroup: {
      title: 'MeOut Group',
      logo: partnerImages.meOutGroup,
      offers: [
        {
          title: 'Részvételi lehetőség külföldi rendezvényen',
          lead: '1-2 hallgatói csapat részére díjmentes részvételi lehetőséget biztosít a MeOut Group valamely 2022-es külföldi rendezvényén (utazási, szállás-és étkezési költségeket a MeOut Group fedezi).'
        },
        {
          title: 'Közös ebéd, céglátogatás',
          lead: 'Érdeklődő hallgatói csapatokat meginvitál egy közös ebédre, céglátogatásra, ahol lehetőséget biztosít projektötlet bemutatására, valamint ökoszizstéma szereplőkkel való találkozásra.'
        },
        {
          title: 'Szakmai mentoráció',
          lead: '2 hallgatói csapat részére havi 2x1 óra (online vagy offline) mentorációt biztosít pitch deck és one-pager készítésben.'
        }
      ]
    },
    brancsKozosseg: {
      title: 'Brancsközösség.hu Zrt.',
      logo: partnerImages.brancsKozosseg,
      offers: [
        {
          title: 'Alumni Pitch night + kedvezményes kampányindítás',
          lead: 'A pitch videót beküldő HSUP alumnis csapatok közül félévente 10-et kiválaszt, akik egy pitch night-on versenghetnek egymással. A legjobb 3 csapat kedvezményes kampányindítási lehetőségben részesül a Brancs közösségi piactéren (jelentkezéshez a levél tárgyában fel kell tüntetni, hogy HSUP Alumni).'
        },
        {
          title: 'Közös ebéd',
          lead: 'Egy alkalommal közös ebéddel összekötött tanácsadást szervez a Brancs és a MyFarm Harta startup-ok megálmodójával, Kövesdi Gáborral vagy a Brancs közösségi piactér ügyvezetőjével, Toplenszki Rékával.'
        },
        {
          title: 'Marketing tanácsadás',
          lead: '1 órás online tanácsadást biztosít marketing témában a Brancs közösségi piactér marketing vezetőjével, Fejes Csillával.'
        },
        {
          title: 'Kedvezményes kampányindítási lehetőség',
          lead: 'Kedvezményes kampányindítási lehetőséget biztosít a Brancs közösségi piactéren a félév végén a Demo Day-en a Brancs kedvenc csapatának.'
        }
      ]
    }
  };

  // Partners are ordered by object KEYS
  const partnersByAbc = Object.keys(partners)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: partners[key]
      }),
      {}
    );

  return partnersByAbc;
}
