/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HeroLeft = () => (
  <div className="md:text-left text-center z-20 my-10 max-w-2xl">
    <h1>Partnerek ajánlatai HSUP csapatok számára</h1>
    <h3 className="py-8">
      Böngéssz a HSUP partnerek ajánlatai között és találd meg a csapatodnak
      megfelelőt!
    </h3>
  </div>
);

const HeroRight = ({ credit }) => (
  <div>
    {credit && (
      <div
        className="rounded md:mt-16"
        style={{
          background:
            'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 100%)'
        }}
      >
        <Img
          alt="credit"
          fluid={credit.childImageSharp.fluid}
          className="flex rounded items-center justify-center m-auto w-64 md:w-96"
        />
      </div>
    )}
  </div>
);

export default function PartnerHero() {
  const { credit } = useStaticQuery(graphql`
    query {
      credit: file(relativePath: { eq: "illu_credit_colorful.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className="p-1">
      <div className="flex flex-wrap py-12 md:py-24 mt-10 mb:mt-0 md:flex-row flex-col">
        <HeroLeft />
        <HeroRight credit={credit} />
      </div>
    </section>
  );
}
