/* eslint-disable react/prop-types */
import GatsbyImage from 'gatsby-image';
import { Element } from 'react-scroll';

import React from 'react';
import usePartners from './hooks/usePartners';

export default function PartnerOfferSection() {
  return (
    <section className="mt-24">
      <h2>Partnerek ajánlatai</h2>
      <PartnerOfferList />
    </section>
  );
}

function PartnerOfferList() {
  const partners = usePartners();

  return (
    <div>
      {Object.keys(partners).map(partnerKey => {
        const partner = partners[partnerKey];
        const { title } = partner;

        return (
          <Element key={title} name={partnerKey}>
            <PartnerOffer partner={partner} />
          </Element>
        );
      })}
    </div>
  );
}

function PartnerOffer(props) {
  const { partner } = props;
  const { offers } = partner;

  return (
    <div className="bg-white mt-10 p-8">
      <PartnerOfferInfo partner={partner} />
      <h2 className="text-lg mt-12">Ajánlatok</h2>
      <PartnerOfferItems offers={offers} />
    </div>
  );
}

function PartnerOfferInfo(props) {
  const { partner } = props;
  const { title, logo } = partner;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {logo && (
        <div className="p-2 w-64 mr-16">
          <GatsbyImage
            alt={title}
            fluid={logo.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            className="h-32"
          />
        </div>
      )}
      <div>
        <h2 className="mt-10">{title}</h2>
      </div>
    </div>
  );
}

function PartnerOfferItems(props) {
  const { offers } = props;
  return (
    <div className="mt-6 grid gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      {offers.map(offer => (
        <PartnerOfferItem key={`${offer.title} ${offer.lead}`} offer={offer} />
      ))}
    </div>
  );
}

function PartnerOfferItem(props) {
  const { offer } = props;
  const { title, lead, paragraphs } = offer;

  const borderStyle = {
    border: '2.35996px solid #F5F6F9',
    borderRadius: '4.71993px'
  };

  const titleStyle = { color: '#0E1655' };

  return (
    <div className="p-4" style={borderStyle}>
      {title ? <h3 style={titleStyle}>{title}</h3> : null}
      <PartnerOfferItemLead lead={lead} />
      {paragraphs?.map(paragraph => (
        <PartnerOfferItemLead key={paragraph} lead={paragraph} />
      ))}
    </div>
  );
}

function ConvertLinks(props) {
  const linkStyle = {
    color: '#80BC00'
  };

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return props.children?.split(urlRegex).map(part => {
    if (part?.match(urlRegex)) {
      return (
        <a
          href={part}
          key={part}
          style={linkStyle}
          target="_blank"
          rel="noreferrer"
        >
          {part}
        </a>
      );
    }

    return part;
  });
}

function PartnerOfferItemLead(props) {
  const { lead } = props;
  const leadStyle = { color: '#455A64' };

  return (
    <p className="mt-4" style={leadStyle}>
      <ConvertLinks>{lead}</ConvertLinks>
    </p>
  );
}
