import React from 'react';
import { Layout } from '../components/common';
import {
  PartnerHeroSection,
  PartnerOfferSection,
  PartnerOverviewSection
} from '../components/partner';

const backgroundStyle = {
  background:
    'linear-gradient(180deg, rgba(245,246,249,1) 0%, rgba(255,255,255,1) 100%)'
};

const Partner = () => {
  return (
    <Layout
      seo={{
        title: 'Partnerek ajánlatai',
        lang: 'hu',
        description: 'Partnerek ajánlatai HSUP csapatok számára'
      }}
      baseRoute="/partner"
    >
      {/* background */}
      <div style={backgroundStyle}>
        {/* horizontal spacing, footer spacing */}
        <div className="px-4 md:px-12 pb-12 xl:container">
          <PartnerHeroSection />
          <PartnerOverviewSection />
          <PartnerOfferSection />
        </div>
      </div>
    </Layout>
  );
};

export default Partner;
